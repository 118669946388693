const checkErrors = (field, form) => {
  const errors = []
  const checks = field.validate
  if (checks.required) {
    if (field.value == null || field.value === '') {
      errors.push({ label: 'validator.required' })
    }
    if (checks.minLength) {
      if (field.value.length < checks.minLength) {
        errors.push({ label: 'validator.minLength', context: { min: checks.minLength } })
      }
    }
    if (checks.minValue) {
      if (Number(field.value) < checks.minValue) {
        errors.push({ label: 'validator.minValue', context: { min: checks.minValue } })
      }
    }
  }
  if (checks.maxValue) {
    if (Number(field.value) > checks.maxValue) {
      errors.push({ label: 'validator.maxValue', context: { max: checks.maxValue } })
    }
  }
  if (checks.equalsTo) {
    const validator = form[checks.equalsTo.field] || {}
    if (field.value !== validator.value) {
      errors.push({ label: 'validator.equalsTo', context: { msg: checks.equalsTo.msg } })
    }
  }

  field.errors = errors.slice(0)
}

const hasErrors = (field) => {
  return field.length
}

const isFormReady = (form) => {
  const keys = Object.keys(form)
  let ready = true
  for (const k of keys) {
    if (hasErrors(form[k].errors)) {
      ready = false
      break
    }
  }

  return ready
}

const hasFormErrors = (form) => {
  const keys = Object.keys(form)
  for (const k of keys) {
    checkErrors(form[k], form)
  }

  return form
}

const validatorMixin = {
  data () {
    return {
      form: {},
      $_field: {
        value: '',
        validate: {},
        errors: [],
      },
    }
  },
  computed: {
    formReady () {
      return isFormReady(this.$data.form)
    },
  },
  methods: {
    translatedErrors (errors) {
      const e = []
      for (const error of errors) {
        e.push(this.$t(error.label, error.context))
      }
      return e
    },
    setFormValue (key, value) {
      if (!this.$data.form[key]) return false
      this.$data.form[key].value = value
      return true
    },
    getFormValue (key) {
      if (!this.$data.form[key]) return undefined
      return this.$data.form[key].value
    },
    setFormData (original = {}) {
      const keys = Object.keys(original)
      for (const k of keys) {
        const field = this.$data.form[k]
        if (!field) continue
        this.$data.form[k] = {
          ...field, value: original[k],
        }
      }
    },
    clearFormData (original = {}) {
      const keys = Object.keys(original)
      for (const k of keys) {
        const field = this.$data.form[k]
        if (!field) continue
        this.$data.form[k] = {
          ...field, value: '',
        }
      }
    },
    getFormData (original = {}) {
      const keys = Object.keys(this.$data.form)
      const form = {}
      for (const k of keys) {
        form[k] = this.$data.form[k].value
      }
      return { ...original, ...form }
    },
    validateAll () {
      this.$data.form = hasFormErrors(this.$data.form)
    },
    validate (key) {
      if (!this.$data.form[key]) return false
      return checkErrors(this.$data.form[key], this.$data.form)
    },
  },
}

export { hasErrors, checkErrors, isFormReady, hasFormErrors, validatorMixin }
